/* global $ */
import React, { useRef, useEffect } from 'react';
import Marquee from "react-fast-marquee";
import MrRacerDownloadLinks from "../../data/ourgames.json";

export default function StuntMania() {
    const videoRef = useRef(null);
    const startTime = 9; // Start at 5 seconds
    const endTime = 16; // End at 10 seconds
    useEffect(() => {
        document.title = "Stunt Mania - ChennaiGames";
        //alert(window.innerWidth);
        if ($('.lightbox-image').length) {
            $('.lightbox-image').fancybox({
                openEffect: 'fade',
                closeEffect: 'fade',
                helpers: {
                    media: {}
                }
            });
        }

        const video = videoRef.current;



        console.log($('.single-item-carousel').length, 'lendge');
        if ($('.single-item-carousel').length) {
            $('.single-item-carousel').owlCarousel({
                loop: true,
                margin: 100,
                nav: false,
                smartSpeed: 2000,
                autoplay: true,
                dots: false,
                // paddingLeft:50,
                // animateOut: 'fadeOut',
                // animateIn: 'fadeIn',
                // navText: [ '<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>' ],
                responsive: {
                    0: {
                        items: 1
                    },
                    600: {
                        items: 1
                    },
                    800: {
                        items: 1
                    },
                    1024: {
                        items: 1
                    }
                }
            });
        }

        var owl = $('.owl-carousel');
        owl.owlCarousel({
            loop:true,
            animateOut: 'slideOutDown',
            animateIn: 'flipInX',
            margin:30,
            items:5,
            smartSpeed:450,
            autoplay:true,
            pagination:true,
            autoplayTimeout:5000,
            autoplayHoverPause:true,
            responsiveClass:true,
            dots:true,
            responsive:{
                0:{
                    items:2
                },
                600:{
                    items:4
                },
                800:{
                    items:4
                },
                1024:{
                    items:4
                }
            }
        })

        if (video) {

            // Set the starting point
            video.currentTime = startTime;

            // Add an event listener for timeupdate
            const handleTimeUpdate = () => {
                if (video.currentTime >= endTime) {
                    video.currentTime = startTime; // Reset to the start time
                }
            };

            video.addEventListener('timeupdate', handleTimeUpdate);

            // Clean up event listener on component unmount
            return () => {
                video.removeEventListener('timeupdate', handleTimeUpdate);
            };
        }
    }, [startTime, endTime]);
    return (
        <>
        
            <div class="video-container">
                <video ref={videoRef} autoPlay muted loop playsInline class="background-video">
                    <source src="../images/stuntmania/trailer.mp4" type="video/mp4" />
                    {/* Your browser does not support the video tag. */}
                </video>
                <div class="video-content">
                    <center>
                        <img src='../images/stuntmania/banner.png' className='stunt-mania-banner' alt='' width={800}/>
                    </center>
                    <br/>
                    {/* <center>
                        <img src='../images/stuntmania/coming_soon.avif' className='stunt-mania-cs' alt='' width={400}/>
                    </center> */}
                    <br/>
                    <div className='row' align="center">
                    <div className='col-lg-5 col-md-12 col-sm-12'></div>
                        <div className='col-lg-1 col-md-12 col-sm-12' style={{padding:'10px'}}>
                        <a class="wow fadeInLeft" href="https://play.google.com/store/apps/details?id=com.chennaigames.mrracer.stuntmania&hl=en_IN&gl=US"  target='_blank'><img alt='Get it on Google Play' src='/images/playstore.avif' className='store-btn'  style={{height:'100%'}}/></a>
                        </div>

                        <div className='col-lg-1 col-md-12 col-sm-12' style={{padding:'10px'}}>
                        <a class="wow fadeInLeft" href='https://apps.apple.com/us/app/mr-racer-stunt-mania/id6740003846' target='_blank'><img alt='Get it on Google Play' src='/images/app_store_v2.avif' className='store-btn'  style={{height:'100%'}}/></a>
                        </div>
                    </div>
                    <center>
                    
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    
                    </center>
                </div>
            </div>
            <div style={{fontFamily:'ATpinco', backgroundColor:'#030321'}}>
            {/* <div className='col-12'>
                <div className='col-12'>

                    <div className="sec-title centered" style={{ paddingTop: '150px' }}>
                        <img src='./images/stuntmania/banner.png' alt='' style={{ maxWidth: '500px' }} />
                    </div>
                    
                    <div className="sec-title centered">
                       <h2>-) Coming Soon (-</h2>
                    </div>
                </div>
                <br/>
               
            </div> */}
            <section class="welcome-section">
                <div class="auto-container">

                    <div class="sec-title centered">
                        <div class="title">Welcome to the Best</div>
                        <h2><span><img src='./images/stuntmania/nitro.avif' alt='LudoX coin' width={80} className='title-img'/></span>Stunt Mania!<span><img src='./images/stuntmania/nitro.avif' alt='LudoX coin' width={80} className='title-img'/></span></h2>
                    </div>

                    <div class="lower-box">
                        <div class="text">Unleash your inner daredevil in MR RACER Stunt Mania, where the road is your playground and every turn is packed with adrenaline! Choose from a garage full of powerful cars, each designed to dominate the tracks and conquer gravity-defying stunts.</div>
                    </div>

                </div>
            </section>

            <section class="call-to-action-section" style={{ background: "#000000b3 url('../images/stuntmania/trailer_bg.avif') repeat-y", }}>
                <div class="auto-container">
                    <div class="content">
                        <a href="https://youtu.be/J1KpS6iezVQ" class="lightbox-image play-box"><span class="flaticon-play-button"><i class="ripple"></i></span></a>
                        <h2>Watch <br /> Trailer</h2>
                    </div>
                </div>
            </section>
            <section class="welcome-section">
                <div class="auto-container">
                <div class="sec-title centered">
                        {/* <div class="title">Welcome to the Best</div> */}
                        <h2>Features</h2>
                    </div>
                    <div class="lower-box">
                        {/* <div class="text">Unleash your inner daredevil in MR RACER Stunt Mania, where the road is your playground and every turn is packed with adrenaline! Choose from a garage full of powerful cars, each designed to dominate the tracks and conquer gravity-defying stunts.</div> */}
                        <div className="column col-lg-12 col-md-12 col-sm-12">
                                    <p className="about-content" style={{textAlign:'left'}}>
                                        <ul class="" style={{ listStyle: 'unset', paddingLeft: '40px' }}>
                                            <li style={{ fontSize: '20px' }}>🔥 Heart-Pounding Racing Action - Master intuitive touch controls to accelerate, steer, and drift your way to victory. Perform jaw-dropping stunts on thrilling ramps and obstacles!</li><br/>
                                            <li style={{ fontSize: '20px' }}>🏆 Thrilling Game Modes - Progress through level-based challenges across unique themes like bustling cities and scenic landscapes—every race is a fresh adrenaline rush!</li><br/>
                                            <li style={{ fontSize: '20px' }}>🤖 Fierce AI Competition - Test your skills against 5 cunning AI opponents. Brace yourself for intense collisions and strategic maneuvers to stay on top.</li><br/>
                                            {/* <li style={{ fontSize: '20px' }}>🚗 Cars That Match Your Style - Unlock an incredible variety of cars to suit your racing vibe. Earn stars to drive faster, sleeker, and more powerful machines.</li> */}
                                            <li style={{ fontSize: '20px' }}>💥 Nail-Biting Challenges - Outsmart dynamic obstacles like flying boxes, tricky barriers, and challenging pitfalls. Avoid respawning to secure your victory!</li><br/>
                                            {/* <li style={{ fontSize: '20px' }}>💰 Rewards That Motivate - Win in-game currency for every successful race. Use it to unlock new cars, upgrade your speed, and showcase your racing dominance.</li> */}
                                            {/* <li style={{ fontSize: '20px' }}>📈 Customizable Upgrades - Fine-tune your car’s Handling, Acceleration, and Top Speed to outpace rivals and own the leaderboard.</li> */}
                                            {/* <li style={{ fontSize: '20px' }}>🎮 Seamless Player Experience - Navigate easily with a sleek main menu, an in-game HUD for real-time tracking, and a post-race screen to celebrate your achievements.</li><br/> */}
                                            <li style={{ fontSize: '20px' }}>📱 Built for Gamers on the Go - Enjoy smooth, offline gameplay optimized for all devices. Immerse yourself in stunning 3D graphics and captivating sound effects.</li><br/>
                                            <li style={{ fontSize: '20px' }}>🌟 Stars to Shine Bright - Collect stars to flaunt your milestones and prove you’re the ultimate racing champion!</li>
                                            {/* <li style={{ fontSize: '20px' }}>Playful learning of Multiplication Tables, develop memory power & training their brain to quickly find the answer with accuracy!</li>
                                            <li style={{ fontSize: '20px' }}>No advertisement, No disturbance & No Sign-in etc.</li>
                                            <li style={{ fontSize: '20px' }}>All features are fully FREE, so no need to pay anything.</li>
                                            <li style={{ fontSize: '20px' }}>And most importantly, it is created for our kids & by the kids (most of the artworks)!</li> */}
                                        </ul>
                                    </p>
                                </div>
                    </div>

                </div>
            </section>
            <section class="facts-section">

                <div class="title-boxed" style={{ backgroundImage: "url(./images/main-slider/stuntmania.avif)" }}>
                    <div class="auto-container">
                        <div class="content wow fadeInLeft" data-wow-delay="0ms">
                            <h2>Step into a world of  heart-pounding stunts and  non-stop racing action!</h2>
                        </div>
                    </div>
                    <br/><br/>
                </div>

                <div class="lower-boxed">
                    <div class="auto-container">
                        <div class="row clearfix">
                 

                            <div class="counter-column col-lg-6 col-md-12 col-sm-12">
                                <div class="inner-column">


                                    {/* <div class="featured-block">
                                        <div class="inner-box">
                                            <div class="icon-box">
                                                <span class="icon flaticon-joystick"></span>
                                            </div>
                                            <h3>785,000</h3>
                                            <div class="text">Games playerd by 6,388 people in 7 days</div>
                                        </div>
                                    </div> */}


                                    {/* <div class="featured-block">
                                        <div class="inner-box">
                                            <div class="icon-box">
                                                <span class="icon flaticon-man"></span>
                                            </div>
                                            <h3>63,000</h3>
                                            <div class="text">Players are available to hit you live</div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>


                            <div class="testimonial-column col-lg-6 col-md-12 col-sm-12" >
						<div class="inner-column wow fadeInRight" data-wow-delay="0ms" >
							
							<div class="single-item-carousel owl-carousel owl-theme">
								
								
								<div class="testimonial-block">
									<div class="inner-box">
										<div class="quote-icon flaticon-quote-1"></div>
                                            <img src='../images/stuntmania/cars/1.avif' alt=''/>
                                            <br/>
										<div class="quote-icon">- BLITZ</div>
									</div>
								</div>
								
							
								<div class="testimonial-block">
									<div class="inner-box">
										<div class="quote-icon flaticon-quote-1"></div>
										<img src='../images/stuntmania/cars/2.avif' alt=''/><br/>
										<div class="quote-icon">- LIZ LEMON</div>
									</div>
								</div>
								
								
								<div class="testimonial-block">
									<div class="inner-box">
										<div class="quote-icon flaticon-quote-1"></div>
										<img src='../images/stuntmania/cars/3.avif' alt=''/><br/>
										<div class="quote-icon">- SHINE</div>
									</div>
								</div>

                                <div class="testimonial-block">
									<div class="inner-box">
										<div class="quote-icon flaticon-quote-1"></div>
										<img src='../images/stuntmania/cars/4.avif' alt=''/><br/>
										<div class="quote-icon">- BUMBLE B</div>
									</div>
								</div>
                                <div class="testimonial-block">
									<div class="inner-box">
										<div class="quote-icon flaticon-quote-1"></div>
										<img src='../images/stuntmania/cars/5.avif' alt=''/><br/>
										<div class="quote-icon">- PEGGY</div>
									</div>
								</div>
                                <div class="testimonial-block">
									<div class="inner-box">
										<div class="quote-icon flaticon-quote-1"></div>
										<img src='../images/stuntmania/cars/6.avif' alt=''/><br/>
										<div class="quote-icon">- THE BEAST</div>
									</div>
								</div>
                                <div class="testimonial-block">
									<div class="inner-box">
										<div class="quote-icon flaticon-quote-1"></div>
										<img src='../images/stuntmania/cars/7.avif' alt=''/><br/>
										<div class="quote-icon">- VIPER</div>
									</div>
								</div>
                                <div class="testimonial-block">
									<div class="inner-box">
										<div class="quote-icon flaticon-quote-1"></div>
										<img src='../images/stuntmania/cars/8.avif' alt=''/><br/>
										<div class="quote-icon">- FLASH</div>
									</div>
								</div>
                                <div class="testimonial-block">
									<div class="inner-box">
										<div class="quote-icon flaticon-quote-1"></div>
										<img src='../images/stuntmania/cars/9.avif' alt=''/><br/>
										<div class="quote-icon">- BOB</div>
									</div>
								</div>

                                <div class="testimonial-block">
									<div class="inner-box">
										<div class="quote-icon flaticon-quote-1"></div>
										<img src='../images/stuntmania/cars/10.avif' alt=''/><br/>
										<div class="quote-icon">- SPOOKY</div>
									</div>
								</div>
								
							</div>
							
						</div>
					</div>

                        </div>
                        
                    </div>
                    
                </div>
            </section>

            {/* <div className='container' >
                <div className="sec-title centered">
                    <div className="title">Game</div>
                    <h2>screenshots</h2>
                </div>

                <div class="owl-carousel">
                    <div> <img src='./images/stuntmania/screenshots/1.jpg' alt=''/></div>
                    
                    <div> <img src='./images/stuntmania/screenshots/3.jpg' alt=''/></div>
                    <div> <img src='./images/stuntmania/screenshots/4.jpg' alt=''/></div>
                    <div> <img src='./images/stuntmania/screenshots/2.jpg' alt=''/></div>
                    <div> <img src='./images/stuntmania/screenshots/5.jpg' alt=''/></div>
                    <div> <img src='./images/stuntmania/screenshots/6.jpg' alt=''/></div>
                    <div> <img src='./images/stuntmania/screenshots/7.jpg' alt=''/></div>
                    <div> <img src='./images/stuntmania/screenshots/8.jpg' alt=''/></div>
                    <div> <img src='./images/stuntmania/screenshots/9.jpg' alt=''/></div>
                </div>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/>
            </div> */}

            <div className='container' >
                <div className="sec-title centered">
                    <img src='../images/stuntmania/key.avif'/>
                    {/* <div className="title">Game</div> */}
                    <br/><br/><br/>
                    {/* <h2><span><img src='./images/stuntmania/arrow_right.avif' alt='LudoX coin' className='title-img'/></span>&nbsp;Coming Soon!&nbsp;<span><img src='./images/stuntmania/arrow_left.avif' alt='LudoX coin' className='title-img' /></span></h2> */}
                </div>

                {/* <div class="owl-carousel">
                    <div> <img src='./images/stuntmania/screenshots/1.jpg' alt=''/></div>
                    
                    <div> <img src='./images/stuntmania/screenshots/3.jpg' alt=''/></div>
                    <div> <img src='./images/stuntmania/screenshots/4.jpg' alt=''/></div>
                    <div> <img src='./images/stuntmania/screenshots/2.jpg' alt=''/></div>
                    <div> <img src='./images/stuntmania/screenshots/5.jpg' alt=''/></div>
                    <div> <img src='./images/stuntmania/screenshots/6.jpg' alt=''/></div>
                    <div> <img src='./images/stuntmania/screenshots/7.jpg' alt=''/></div>
                    <div> <img src='./images/stuntmania/screenshots/8.jpg' alt=''/></div>
                    <div> <img src='./images/stuntmania/screenshots/9.jpg' alt=''/></div>
                </div> */}
                <br/><br/><br/>
            </div>
</div>
        </>
    )
}